import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _594697c8 = () => interopDefault(import('../pages/begeleiders/index.vue' /* webpackChunkName: "pages/begeleiders/index" */))
const _ae2ca670 = () => interopDefault(import('../pages/blogs/index.vue' /* webpackChunkName: "pages/blogs/index" */))
const _458512d8 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _f7466fb2 = () => interopDefault(import('../pages/ouders/index.vue' /* webpackChunkName: "pages/ouders/index" */))
const _29e1b9e0 = () => interopDefault(import('../pages/over.vue' /* webpackChunkName: "pages/over" */))
const _002e0746 = () => interopDefault(import('../pages/registreren.vue' /* webpackChunkName: "pages/registreren" */))
const _38a391c7 = () => interopDefault(import('../pages/vragen.vue' /* webpackChunkName: "pages/vragen" */))
const _e69131ec = () => interopDefault(import('../pages/vragen/index.vue' /* webpackChunkName: "pages/vragen/index" */))
const _e9fe747c = () => interopDefault(import('../pages/vragen/_slug.vue' /* webpackChunkName: "pages/vragen/_slug" */))
const _7bf7b4ec = () => interopDefault(import('../pages/begeleiders/netwerkactiviteiten/index.vue' /* webpackChunkName: "pages/begeleiders/netwerkactiviteiten/index" */))
const _c4bc0410 = () => interopDefault(import('../pages/begeleiders/tarieven.vue' /* webpackChunkName: "pages/begeleiders/tarieven" */))
const _d557f7ae = () => interopDefault(import('../pages/begeleiders/toolbox.vue' /* webpackChunkName: "pages/begeleiders/toolbox" */))
const _ddae05ae = () => interopDefault(import('../pages/ouders/begeleiders/index.vue' /* webpackChunkName: "pages/ouders/begeleiders/index" */))
const _1be906ba = () => interopDefault(import('../pages/ouders/omgeving.vue' /* webpackChunkName: "pages/ouders/omgeving" */))
const _ae6e058e = () => interopDefault(import('../pages/ouders/tarieven.vue' /* webpackChunkName: "pages/ouders/tarieven" */))
const _4fed6ed9 = () => interopDefault(import('../pages/begeleiders/netwerkactiviteiten/trainingen.vue' /* webpackChunkName: "pages/begeleiders/netwerkactiviteiten/trainingen" */))
const _4b1df49f = () => interopDefault(import('../pages/begeleiders/netwerkactiviteiten/webinars.vue' /* webpackChunkName: "pages/begeleiders/netwerkactiviteiten/webinars" */))
const _2d497b86 = () => interopDefault(import('../pages/ouders/begeleiders/zoeken.vue' /* webpackChunkName: "pages/ouders/begeleiders/zoeken" */))
const _7a4113a4 = () => interopDefault(import('../pages/begeleiders/netwerkactiviteiten/_slug.vue' /* webpackChunkName: "pages/begeleiders/netwerkactiviteiten/_slug" */))
const _e11b483e = () => interopDefault(import('../pages/ouders/begeleiders/_slug.vue' /* webpackChunkName: "pages/ouders/begeleiders/_slug" */))
const _b199e900 = () => interopDefault(import('../pages/blogs/_slug.vue' /* webpackChunkName: "pages/blogs/_slug" */))
const _770e1946 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _568d529e = () => interopDefault(import('../pages/*.vue' /* webpackChunkName: "pages/*" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/begeleiders",
    component: _594697c8,
    name: "begeleiders"
  }, {
    path: "/blogs",
    component: _ae2ca670,
    name: "blogs"
  }, {
    path: "/contact",
    component: _458512d8,
    name: "contact"
  }, {
    path: "/ouders",
    component: _f7466fb2,
    name: "ouders"
  }, {
    path: "/over",
    component: _29e1b9e0,
    name: "over"
  }, {
    path: "/registreren",
    component: _002e0746,
    name: "registreren"
  }, {
    path: "/vragen",
    component: _38a391c7,
    children: [{
      path: "",
      component: _e69131ec,
      name: "vragen"
    }, {
      path: ":slug",
      component: _e9fe747c,
      name: "vragen-slug"
    }]
  }, {
    path: "/begeleiders/netwerkactiviteiten",
    component: _7bf7b4ec,
    name: "begeleiders-netwerkactiviteiten"
  }, {
    path: "/begeleiders/tarieven",
    component: _c4bc0410,
    name: "begeleiders-tarieven"
  }, {
    path: "/begeleiders/toolbox",
    component: _d557f7ae,
    name: "begeleiders-toolbox"
  }, {
    path: "/ouders/begeleiders",
    component: _ddae05ae,
    name: "ouders-begeleiders"
  }, {
    path: "/ouders/omgeving",
    component: _1be906ba,
    name: "ouders-omgeving"
  }, {
    path: "/ouders/tarieven",
    component: _ae6e058e,
    name: "ouders-tarieven"
  }, {
    path: "/begeleiders/netwerkactiviteiten/trainingen",
    component: _4fed6ed9,
    name: "begeleiders-netwerkactiviteiten-trainingen"
  }, {
    path: "/begeleiders/netwerkactiviteiten/webinars",
    component: _4b1df49f,
    name: "begeleiders-netwerkactiviteiten-webinars"
  }, {
    path: "/ouders/begeleiders/zoeken",
    component: _2d497b86,
    name: "ouders-begeleiders-zoeken"
  }, {
    path: "/begeleiders/netwerkactiviteiten/:slug",
    component: _7a4113a4,
    name: "begeleiders-netwerkactiviteiten-slug"
  }, {
    path: "/ouders/begeleiders/:slug",
    component: _e11b483e,
    name: "ouders-begeleiders-slug"
  }, {
    path: "/blogs/:slug",
    component: _b199e900,
    name: "blogs-slug"
  }, {
    path: "/",
    component: _770e1946,
    name: "index"
  }, {
    path: "/*",
    component: _568d529e,
    name: "*"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
